/* ---------- Back to Top ----------------------------------------------------- */

.cd-top {
  display: inline-block;
  height: 60px;
  width: 60px;
  border-radius: 100px;
  position: fixed;
  bottom: 30px;
  right: 40px;
  z-index: 2;
  /* image replacement properties */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: url('../images/pfeil-up.svg') no-repeat center;
  background-size: 50%; /* Adjust the size as needed */
  background-color: var(--primary);
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s, visibility 0s .3s, background-color .3s;
}

.cd-top.cd-is-visible, .cd-top.cd-fade-out {
  transition: opacity .3s, visibility 0s 0s;
}

.cd-top.cd-is-visible {
  visibility: visible;
  opacity: 0.5;
}

.cd-top.cd-fade-out {
  opacity: 0.5;
}

.cd-top:hover {
  opacity: 1;
}


@media only screen and (min-width: 768px) {
  .cd-top {
    height: 60px;
    width: 60px;
    right: 40px;
    bottom: 40px;
  }
}

@media only screen and (min-width: 1024px) {
  .cd-top {
    height: 60px;
    width: 60px;
    right: 40px;
    bottom: 50px;
  }
}




